import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_a_muse_2.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";

// export default Page

const Index = (props) => {
   return (
      <>
        <Helmet
          title="八坪大-歐陽如修 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "位於萬華隆昌街的八坪大有著獨特的地理位置，巧妙地橫跨在艋舺於西門町之間，既隱身於傳統，又接近於時尚潮流。店內的空間也如同店名，只有八坪大。從去年開始手做有機小農料理前往街頭送餐，還同時供與弱勢團體送暖，且常於牆面上布置主題藝術展，店內有著各種特色漫畫，尤其以台灣本地創作者的作品為主要收藏。",
            },
            {
              property: `og:title`,
              content: "八坪大-歐陽如修 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "位於萬華隆昌街的八坪大有著獨特的地理位置，巧妙地橫跨在艋舺於西門町之間，既隱身於傳統，又接近於時尚潮流。店內的空間也如同店名，只有八坪大。從去年開始手做有機小農料理前往街頭送餐，還同時供與弱勢團體送暖，且常於牆面上布置主題藝術展，店內有著各種特色漫畫，尤其以台灣本地創作者的作品為主要收藏。歐陽如修",
            },
            // {
            //   property: `og:url`,
            //   content: 'https://online.home-to-all.com' + window.location.pathname,
            // },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/project_a_muse_2-2.jpg')
            }
          ]}
        >
        </Helmet>
        <IndexSPA />
        {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;